.container{
  display: flex;
  position: relative;
  top: 0;
  margin: 40% auto;
  justify-content: center;
}
@media screen and (min-width: 767px){
  .container {
    margin: 15% auto;
  }
}

@keyframes ldio_oefckr7uivn {
  0% { background: #ff0000 }
  12.5% { background: #ff0000 }
  12.625% { background: #2e3192 }
  100% { background: #2e3192 }
}
.ldio_oefckr7uivn div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #2e3192;
  animation: ldio_oefckr7uivn 1s linear infinite;
}
.spinner_blocks{
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio_oefckr7uivn{
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; 
}
.ldio_oefckr7uivn div 
{ box-sizing: content-box; }
